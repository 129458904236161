import './main.css'
import { Elm } from './Main.elm'
import * as serviceWorker from './serviceWorker'

var win = window,
  doc = document,
  docElem = doc.documentElement,
  body = doc.getElementsByTagName('body')[0],
  w = win.innerWidth || docElem.clientWidth || body.clientWidth,
  h = win.innerHeight || docElem.clientHeight || body.clientHeight

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: { 
    now : +(new Date),
    width: w,
    height: h
  }
})

app.ports.out.subscribe(({ type, data }) => {
  switch (type) {
    case 'save':
      if (!data) break
      localStorage.setItem('data', JSON.stringify(data))
      break
    case 'load':
      const result = localStorage.getItem('data')
      if (!result) break
      try {
        app.ports.inn.send(JSON.parse(result))
      } catch (e) {
        console.error('load', e)
      }
      break
  }
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
